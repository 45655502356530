.CommingSoon {
  background:url("../../assets/img/hp_about_1-min.jpg") no-repeat,url("../../assets/img/pattern_light.jpg") repeat;
  background-position:center bottom;
  width:100%;
  background-size:cover;
  position:relative;
  padding-bottom: 50px;
  .section-heading {
    text-align: center;
    width: 70%;
    padding: 70px 0;
    margin: 0 auto;
  }
  a {
    color: #b68e14;
    font-weight: bold;
  }
  a:focus, a:hover {
    text-decoration: none;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
  }
  #modal-popup-newsletter .modal-dialog {
    max-width: 960px;
  }
  .title-modal {
    font-size: 33px;
    font-weight: bold;
  }
}
.content-soon {
  position: relative;
  padding: 50px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.content-soon-left {
  position: relative;
  padding: 50px;
  height: 100%;
  width: 100%;
  z-index: 1;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    // background-image: url("../../assets/imgat/bg.png");
    background-repeat: no-repeat;
    z-index: -1;
    object-fit: cover;
  }
}
.comming-img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  // background-image: url("../../assets/imgat/bg.png");
  background-repeat: no-repeat;
  z-index: -1;
}
.skull {
  height: 50px;
  width: 100%;
  object-fit: contain;
}

.parent {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Example gap between child elements */
}



@media (max-width:768px){
  .parent {
      display: flex;
      flex-direction: column;
  }
  .CommingSoon .section-heading {
    width: 90%;
    padding-bottom: 0;
  }
  .content-soon {
    padding: 10px;
  }
  .content-soon-left {
    min-height: 500px;
  }
}

