.closeAccordion .accordionItemContent{
    height:0px;
    transition:height 1s ease-out;
    -webkit-transform: scaleY(0);
    -o-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    display:block;
    -webkit-transition: -webkit-transform 0.4s ease-out;
    -o-transition: -o-transform 0.4s ease;
    -ms-transition: -ms-transform 0.4s ease;
    transition: transform 0.4s ease;
    box-sizing: border-box;
}
.openAccordion .accordionItemContent{
    background-color: #fff;
    width: 100%;
    display:block;
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    -o-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;

    -webkit-transition: -webkit-transform 0.4s ease-in;
    -o-transition: -o-transform 0.4s ease;
    -ms-transition: -ms-transform 0.4s ease;
    transition: transform 0.4s ease;
    box-sizing: border-box;
}
.accordionContentBg{
    background-color: #fff;
}

form .upload-wrap .upload-file-info.selected:before {
    content: url(../../assets/img/icon-file.png);
    width: 48px;
    height: 48px;
    position: absolute;
    left: -60px;
    top: -11px;
}