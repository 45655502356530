footer#main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:#000;
    width:100%;
    padding: 40px;
    overflow:auto
}
footer#main .around-content {
    width: 300px;
}


footer#main .left .copyright{
  font-size:18px;
  line-height:32px;
  color:#fff
}

footer#main ul.social-links {
  list-style: none;
  padding-left: 0;
}

footer#main ul.social-links li a {
  display: flex;
  align-items: center;
}
footer#main ul.social-links li a svg {
  margin: 4px;
}

footer#main ul.footer-menu {
  display: flex;
  flex-wrap: wrap;
  text-align:center;
  padding-left: 0;
}

footer#main ul li.active a{
  color:#dab74f
}
footer#main ul li a{
  font-size:22px;
  font-weight:700;
  color:#fff
}

footer#main ul li a:hover{
  color:#dab74f
}

footer#main ul:not(.social-links) li:not(:last-child):after{
  content:url(../../assets/img/dot.svg);
  width:4px;
  height:4px;
  display:inline-block;
  position:relative;
  top:-5px;
  left:36px
}

footer#main .right{
  color:#fff;
  font-size:15px;
}

@media (min-width:768px) {
  footer#main ul:not(.social-links) li:not(:last-child){
      margin-right: 6vw
  }
}
@media (min-width:768px) and (max-width:991px){
  footer#main ul li:not(:last-child):after{
      left: 3vw
  }
}
@media (max-width:1160px){
  footer#main ul li a{
      font-size: 20px;
  }
}
@media (max-width:767px){
  footer#main ul li:not(:last-child):after{
      display:none
  }
  footer#main ul:not(.social-links) li:not(:last-child){
    margin-right: 6vw
  }
  footer#main ul:not(.social-links) li:not(:last-child):after {
    left: 3vw;
  }
  .copyright {
    display: flex;
    justify-content: center;
  }
  .social-links {
    text-align: center;
  }
}

.social-links .fb svg {
  width: 30px;
  height: 30px;
  transition: all 0.275s ease-out;
  transform-style: preserve-3d;
  stroke: #fff;
  -webkit-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out;
}
.social-links li:hover .fb svg {
  stroke: "#fff";
}
.social-links li:hover .fb svg path:first-child {
  fill: #1877F2;
  -webkit-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out;
}
.social-links li:hover .fb svg path:nth-child(2) {
  fill: #fff;
  -webkit-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out;
}

.social-links li:hover .in svg rect {
fill: #1275B1;
-webkit-transition:all .3s ease-in-out;
-o-transition:all .3s ease-in-out;
transition:all .3s ease-in-out;
}


  
footer#main ul li a span {
font-size: 18px;
}

footer#main ul li a svg {
border: 1px solid #fff;
padding: 2px;
margin: 3px 0;
border-radius: 100%;
}

.social-links li .ig svg path {
fill: url(#mygradient);
}

#mygradient stop.start-color {
fill: red;
}

#mygradient stop.end-color {
fill: blue;
}

.social-links li svg,
.social-links li svg path {
  -webkit-transition:all .3s ease-in-out;
  -o-transition:all .3s ease-in-out;
  transition:all .3s ease-in-out;
}
.social-links li:hover .tik svg path {
fill: #fff;
-webkit-transition:all .3s ease-in-out;
-o-transition:all .3s ease-in-out;
transition:all .3s ease-in-out;
}
.social-links li:hover .tik svg path:first-child {
fill: #EE1D52;
}
.social-links li:hover .tik svg path:nth-child(2) {
  fill: #000;
}

.social-links li:hover .tik svg path:nth-child(3) {
  fill: #69C9D0;
}

.social-links li:hover .tik svg path:nth-child(4) {
  fill: #69C9D0;
}

.social-links li:hover .tik svg path:nth-child(5) {
  fill: #69C9D0;
}

.left-content {
  display: flex;
  align-items: center;
}

@media (min-width: 1366px) {
  footer#main .container-custom .left .copyright {
      display: inline-block;
  }
}
@media (min-width: 1199px) {
  .left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1020px) {
  .left-content {
    flex-direction: column;
  }
  footer#main {
    padding: 35px 10px;
  }
}

@media (max-width: 620px) {
  footer#main {
    flex-direction: column-reverse;
  }
  .left-content span.copyright {
    width: 100%;
    text-align: center;
    display: block;
  }
}

@media (min-width: 1366px) {
  footer#main .container-custom .left .copyright {
      display: inline-block;
  }
}



.wrapper-icon {
  display: inline-flex;
  list-style: none;
}

.wrapper-icon .icon {
  position: relative;
  /* background: #ffffff; */
  /* border-radius: 50%; */
  /* padding: 15px; */
  /* margin: 10px; */
  /* width: 50px; */
  /* height: 50px; */
  /* font-size: 18px; */
  /* margin: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper-icon .tooltip-icon {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper-icon .tooltip-icon::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper-icon .icon:hover .tooltip-icon {
  top: -45px;
  /* left: 50%; */
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper-icon .icon:hover span,
.wrapper-icon .icon:hover .tooltip-icon {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper-icon .facebook:hover .tooltip-icon,
.wrapper-icon .facebook:hover .tooltip-icon::before {
  background: #1877F2;
  color: #ffffff;
}
.wrapper-icon .twitter:hover .tooltip-icon,
.wrapper-icon .twitter:hover .tooltip-icon::before {
  background: #1DA1F2;
  color: #ffffff;
}
.wrapper-icon .linkedin:hover .tooltip-icon,
.wrapper-icon .linkedin:hover .tooltip-icon::before {
  background: #0077B5;
}
.wrapper-icon .instagram:hover .tooltip-icon,
.wrapper-icon .instagram:hover .tooltip-icon::before {
  background: #E4405F;
  color: #ffffff;
}
.wrapper-icon .tiktok:hover .tooltip-icon,
.wrapper-icon .tiktok:hover .tooltip-icon::before {
  background-color: #e74c3c; 
  color: #fff; /* Tooltip text color */
}

.wrapper-icon .youtube:hover,
.wrapper-icon .youtube:hover .tooltip-icon,
.wrapper-icon .youtube:hover .tooltip-icon::before {
  background: #CD201F;
  color: #ffffff;
}

.follow-us {
  height: 44px;
  margin-bottom: 15px;
}

.social-title {
  margin: 0;
}

.social-group {
  display: flex;
}

.social-group1 {
  padding-right: 30px;
}

  

@media (max-width: 620px) {
  footer#main {
    flex-direction: column-reverse;
    padding: 20px 0;
  }
  .left-content span.copyright {
    width: 100%;
    text-align: center;
    display: block;
  }
}

@media (max-width: 520px) {
  .social-group {
    flex-direction: column;
  }
  .social-group1 {
    padding-right: 0;
  }
}

@media (min-width: 1366px) {
  footer#main .container-custom .left .copyright {
      display: inline-block;
  }
}